@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */

@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter/Inter-Regular.ttf");
}

@font-face {
    font-family: "Ubuntu";
    src: url("../fonts/Ubuntu/Ubuntu-Regular.ttf");
}

body {
    margin: 0;
    font-family: "Inter";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* custom */

.font-ubuntu {
    font-family: "Ubuntu";
}

.font-inter {
    font-family: "Inter";
}

.title {
    font-family: "Ubuntu";
    font-weight: 700;
}

h1 {
    font-family: Ubuntu;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

h2 {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

h3 {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #616161;
}

p {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* antd radio */
.bigRadio .ant-radio{
    align-self: auto;
}

.ant-radio-wrapper-checked .ant-radio-inner {
    /* color:#C7BA9A !important; */
    background-color: #ffffff !important;
    border-color: #c7ba9a !important;
}

.ant-radio-inner:after {
    background-color: #c7ba9a !important;
}

.ant-radio-wrapper {
    font-size: 18px;
}

.ant-radio-wrapper .ant-radio-inner::after {
    width: 30px !important;
    height: 30px !important;
    margin-block-start: -15px !important;
    margin-inline-start: -15px !important;
}
.ant-radio-wrapper .ant-radio-checked::after{
    border: none !important;
}



.qrCode .ant-skeleton-input{
    width: 134px !important;
    height: 134px !important;
    min-width: 134px !important;
}


/* antd input */
.ant-input, .ant-input-password, .ant-input-affix-wrapper {
    padding: 0 !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

/* antd checkbox */
.ant-checkbox-wrapper {
    font-size: 16px;
}
.bigCheckBox .ant-checkbox{
    align-self: auto;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #c7ba9a !important;
    border-color: #c7ba9a !important;
}

.l-v-container-code .v-code-input {
    border-bottom: 1px solid #d8d8d8;
    margin-right: 30px;
    width: 35px !important;
    height: 50px;
    font-size: 18px;
}

.l-v-container-code .v-code-focus {
    /* border-bottom: 2px solid #05004d; */
    outline: none !important;
}
.l-v-c-content p{
    margin-bottom: 8px;
}

/* message */

.ant-message-notice-content{
    padding:0 !important;
}

.ant-message-typical,.ant-message-info,.ant-message-warning,.ant-message-error,.ant-message-success{
    padding:10px 15px 10px 15px;
}

.ant-message-info{
    color:#8B8BEA;
    background: #F7F5FF;
}
.ant-message-warning{
    color: #CA942D;
    background: #FAF0DD;
}
.ant-message-error{
    color: #FC5454;
    background: #FDE9E9;
}
.ant-message-success{
    color: #36CC66;
    background: #E7FCEF;
}
.ant-upload-list-item-undefined{
    color: red;
}



.ant-qrcode{
    padding: 0 !important;
    justify-content: flex-end !important;
}

.ant-modal-content {
    padding: 30px !important;
}
.ant-timeline .ant-timeline-item-head{
    background-color: transparent !important;
}

.contract-view::-webkit-scrollbar-track{
    background-color: transparent;
}
.contract-view::-webkit-scrollbar{
    width: 6px;
    background-color: transparent;
}
.contract-view::-webkit-scrollbar-thumb{
    border-radius: 100px;
    box-shadow: none;
    background-color: #05004D
}

.ant-form-item-explain-success{
    color:#36CC66;
}
/* btn */
.ant-btn-default:disabled{
    /* border: none; */
    opacity: 50%;
}

@media screen and (max-width: 640px) {
    p {
        font-size: 16px;
    }
    .ant-radio-wrapper {
        font-size: 16px;
    }

    .ant-qrcode{
        padding: 0 !important;
        justify-content: center !important;
    }
}

/* animation */
.fadeIn {
    animation: fadeIn ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
